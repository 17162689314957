define([
    'santa-components',
    'prop-types',
    'lodash',
    'componentsCore',
    'coreUtils'
], function (
    santaComponents,
    PropTypes,
    _,
    componentsCore,
    coreUtils
) {
    'use strict';

    function getLinkAttributes(linkProps) {
        let attributes;
        if (_.isEmpty(linkProps)) {
            attributes = {parentConst: santaComponents.utils.createReactElement.bind(null, 'div')};
        } else {
            attributes = linkProps;
            attributes.style = {cursor: 'pointer'};
        }

        return attributes;
    }

    function getStyleHtml(styleArray, styleId, id) {
        return _.reduce(styleArray, (res, styleLine) => `${res} .${styleId}_${id} ${styleLine}`, '');
    }

    function getDropShadowStyle(styleId, id, colorsMap, {x, y, blurRadius, color, opacity}) {
        const hexColor = coreUtils.colorParser.getHexColor(colorsMap, color);
        const hexOpacity = Math.round(opacity * 255).toString(16).padStart(2, '0');
        return `
.${styleId}_${id} {
    filter: drop-shadow(${x}px ${y}px ${blurRadius}px ${hexColor}${hexOpacity})
}
@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance:none) {
        .${styleId}_${id} {
            will-change: filter;
        }
    }
}
        `;
    }

    return {
        displayName: 'VectorImage',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            id: santaComponents.santaTypesDefinitions.Component.id,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            style: santaComponents.santaTypesDefinitions.Component.style,
            svgId: santaComponents.santaTypesDefinitions.VectorImage.svgId,
            link: santaComponents.santaTypesDefinitions.VectorImage.link,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            compDesign: santaComponents.santaTypesDefinitions.Component.compDesign,
            theme: santaComponents.santaTypesDefinitions.Component.theme,
            colorsMap: santaComponents.santaTypesDefinitions.Theme.colorsMap,
            flipTransformStyle: santaComponents.santaTypesDefinitions.VectorImage.flipTransformStyle,
            svgString: santaComponents.santaTypesDefinitions.VectorImage.svgString,
            svgInfo: santaComponents.santaTypesDefinitions.VectorImage.svgInfo,
            svgType: santaComponents.santaTypesDefinitions.VectorImage.svgType,
            overrideColorsAsCss: santaComponents.santaTypesDefinitions.VectorImage.overrideColorsAsCss,
            shapeStyle: santaComponents.santaTypesDefinitions.VectorImage.shapeStyle,
            preserveViewBox: santaComponents.santaTypesDefinitions.VectorImage.preserveViewBox,
            shouldScaleStroke: santaComponents.santaTypesDefinitions.VectorImage.shouldScaleStroke,
            a11yHidden: PropTypes.bool,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen
        },

        getSvgContent(strokeWidth, hasShadow) {
            const hasStroke = !!strokeWidth;
            return {
                style: _.assign({}, this.props.flipTransformStyle, this.props.shapeStyle, this.getResponsiveOverrides(strokeWidth)),
                className: this.classSet({
                    [this.props.id]: true,
                    'non-scaling-stroke': !this.props.shouldScaleStroke,
                    'with-overflow': this.isOverflow(hasStroke, hasShadow)
                }),
                key: this.props.compProp.displayMode + (hasStroke ? '_stroke' : '_nostroke'),
                dangerouslySetInnerHTML: {__html: this.props.svgString}
            };
        },
        isOverflow(hasStroke, hasShadow) {
            return hasShadow || hasStroke;
        },
        isShadowFilter() {
            return this.props.isExperimentOpen('bv_shadow_filter') && this.props.isResponsive && this.props.compDesign && this.props.compDesign.shadow;
        },
        getResponsiveOverrides(strokeWidth) {
            if (strokeWidth && this.props.isResponsive) {
                return {
                    padding: `calc(${strokeWidth}px / 2)`,
                    width: `calc(100% - ${strokeWidth}px)`,
                    height: `calc(100% - ${strokeWidth}px)`
                };
            }
            return {};
        },
        getSkinProperties() {
            let styleHtml = '';
            const strokeWidth = _.get(this.props.shapeStyle, 'strokeWidth');
            const viewBox = _.get(this.props.svgInfo, 'viewBox', '');
            const shadowData = _.get(this.props.compDesign, 'shadow');
            const refData = {
                '': {
                    'data-svg-id': this.props.svgId,
                    'data-svg-type': this.props.svgType,
                    'data-display-mode': this.props.compProp.displayMode,
                    'data-strokewidth': strokeWidth,
                    'data-viewbox': viewBox,
                    'data-preserve-viewbox': this.props.preserveViewBox ? 'preserve' : 'ignore',
                    'aria-hidden': this.props.a11yHidden ? 'true' : undefined,
                    style: this.props.style
                },
                'link': getLinkAttributes(this.props.link),
                'svg': this.getSvgContent(strokeWidth, !!shadowData)
            };

            if (!_.isEmpty(this.props.overrideColorsAsCss)) {
                styleHtml += getStyleHtml(this.props.overrideColorsAsCss, this.props.styleId, this.props.id);
            }

            if (this.isShadowFilter()) {
                styleHtml += getDropShadowStyle(this.props.styleId, this.props.id, this.props.colorsMap, shadowData);
            }

            if (styleHtml) {
                refData[''].addChildrenBefore = [
                    santaComponents.utils.createReactElement('style', {
                        dangerouslySetInnerHTML: {__html: styleHtml}
                    })
                ];
            }
            return refData;
        }
    };
});
